@if (iconClass) {
<i [ngClass]="iconClass"
  [style.color]="iconColor"></i>
}
<a class="vi-link"
  [ngClass]="{'accessible': accessibilityMode, 'with-inline': inline}"
  [target]="role !== 'link' ? '' : target"
  [attr.role]="role"
  [attr.aria-label]="text"
  [attr.title]="text"
  [style]="linkColorStyle"
  (viUiAccessibleClick)="linkClicked.emit(href)"
  [href]="href">
  <vi-ui-text [inline]="inline"
    [size]="size"
    [text]="text"
    color="'currentColor'">
  </vi-ui-text>
</a>
